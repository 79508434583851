var youkuVideoEmbed = {
  addYoukuVideo: function(youkuId, iframeId) {
    var youku_src = '//player.youku.com/embed/' + youkuId + '?autoplay=1';
    var ifrm = document.createElement("IFRAME");
    ifrm.setAttribute("src", youku_src);
    ifrm.style.width = "100%";
    ifrm.style.height = "100%";
    ifrm.style.border = "0";
    return ifrm;
  }
};
